'use client';

import { useEffect, useState } from 'react';

export default function useClientMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const handleMatchChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    // safari currently doesn't support add/removeEventListener, so we use add/removeListener
    // add/removeListener are maybe marked as deprecated, but that could be wrong
    //     see https://github.com/microsoft/TypeScript/issues/32210
    mediaQueryList.addListener(handleMatchChange);
    // mediaQueryList.addEventListener('change', handleMatchChange);
    setMatches(mediaQueryList.matches);

    return () => mediaQueryList.removeListener(handleMatchChange);
    // return () => {
    //   mediaQueryList.removeEventListener('change', handleMatchChange);
    // };
  }, [query]);

  return matches;
}
