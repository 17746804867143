import 'react-slideshow-image/dist/styles.css';
import Image from 'next/image';
import type { ImageProps } from 'next/image';
import React from 'react';
import { Fade } from 'react-slideshow-image';
import Link from 'next/link';
import defaultImages from '@/lib/default-images';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import styles from '../../styles/Banner.module.css';
import Container from './Container';

interface MyImageProps extends ImageProps {
  url?: string;
  // height: number;
  // width: number;
}

type Props = {
  images: Array<MyImageProps>;
  mobileImages?: Array<MyImageProps>;
};

const buildImage = (
  image: MyImageProps,
  priority: boolean,
  optimised: boolean
) => {
  return (
    <Image
      alt={image.alt}
      src={image.src}
      height={image.height}
      width={image.width}
      priority={priority}
      className={styles.slideImage}
      loading={priority ? 'eager' : 'lazy'}
      placeholder="blur"
      blurDataURL={defaultImages.default}
      sizes="100vw"
      unoptimized={optimised}
    />
  );
};

export default function Banner({ images, mobileImages }: Props) {
  let imageList = images;
  const isMobile = useClientMediaQuery('(max-width: 640px)');
  if (mobileImages && mobileImages?.length > 0 && isMobile) {
    imageList = mobileImages;
  }

  return (
    <section className={styles.banner}>
      <Container className="slide-container">
        {imageList.length > 1 && (
          <Fade arrows={true} pauseOnHover={true}>
            {imageList.map((image, index) => {
              const imageBuild = buildImage(
                image,
                index === 0 || index === 1,
                isMobile
              );

              return image?.url ? (
                <Link
                  key={index}
                  href={image.url}
                  title={image.alt ? `Read more about ${image.alt}` : ''}
                >
                  {imageBuild}
                </Link>
              ) : (
                <div key={index}>{imageBuild}</div>
              );
            })}
          </Fade>
        )}
        {imageList[0] &&
          imageList.length === 1 &&
          buildImage(imageList[0], true, isMobile)}
      </Container>
    </section>
  );
}
